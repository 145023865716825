'use client';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Drawer, DrawerContent, DrawerDescription, DrawerTitle, DrawerTrigger } from '../ui/drawer';
import { cn } from '@/lib/utils';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';
type Props = {
  trigger?: ReactNode;
  heading?: string;
  closeCTA?: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  contentClassnames?: string;
  onCleanup?: () => void;
  fullWidth?: boolean;
};
const ResponsivePopover = ({
  trigger,
  defaultOpen = false,
  onCleanup,
  contentClassnames,
  children,
  fullWidth
}: Props) => {
  const handleCleanup = (open: boolean) => {
    if (!open) {
      onCleanup?.();
    }
  };
  return <>
      <div className="hidden md:block">
        <Popover defaultOpen={defaultOpen} onOpenChange={handleCleanup} data-sentry-element="Popover" data-sentry-source-file="ResponsivePopover.tsx">
          {trigger && <PopoverTrigger className={cn('trigger', fullWidth && 'w-full')}>
              {trigger}
            </PopoverTrigger>}
          <PopoverContent className={cn('mt-2 sm:max-w-[425px] ', contentClassnames)} align="end" data-sentry-element="PopoverContent" data-sentry-source-file="ResponsivePopover.tsx">
            <div className="flex flex-col">{children}</div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="block md:hidden">
        <Drawer defaultOpen={defaultOpen} onOpenChange={handleCleanup} data-sentry-element="Drawer" data-sentry-source-file="ResponsivePopover.tsx">
          <DrawerDescription className="hidden" data-sentry-element="DrawerDescription" data-sentry-source-file="ResponsivePopover.tsx"></DrawerDescription>
          {trigger && <DrawerTrigger className={cn(fullWidth && 'w-full')}>{trigger}</DrawerTrigger>}
          <DrawerContent
        // side="bottom"
        className={cn('rounded-t-lg p-4 pb-10 md:pb-5', contentClassnames)} onOpenAutoFocus={e => {
          e.preventDefault();
        }} data-sentry-element="DrawerContent" data-sentry-source-file="ResponsivePopover.tsx">
            <div className="flex flex-col mt-6">{children}</div>
            <VisuallyHidden.Root data-sentry-element="unknown" data-sentry-source-file="ResponsivePopover.tsx">
              <DrawerTitle data-sentry-element="DrawerTitle" data-sentry-source-file="ResponsivePopover.tsx">title</DrawerTitle>
            </VisuallyHidden.Root>
          </DrawerContent>
        </Drawer>
      </div>
    </>;
};
export default ResponsivePopover;