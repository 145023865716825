import { useAccount } from 'wagmi';

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { requestApp } from '@/lib/api';
import { formatNumber, formatNumberWithDecimal } from '@/lib/utils';
import { calculateUserPercentile } from '@/lib/xp';
import usePortfolioStats from '../portfolio/usePortfolioStats';

export type BadgeData = {
  activateTs: number;
  _id: string;
  badge: string;
  userAddress: string;
  __v: number;
  badgeInfo: {
    lastTradeDate?: string;
    streakLength?: number;
    message?: string;
  };
  badgeLevel: number;
  badgeType: string;
  boostValue: number;
  createdAt: string;
  status: string;
  updatedAt: string;
};

export type PointsResponse = {
  success: boolean;
  points: {
    _id: string;
    userAddress: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    totalPoints: number;
    volumeBoostPoints: number;
    volumePoints: number;
    cumulativeTradingVolume: number;
    referralBoostPoints: number;
    referralPoints: number;
    cumulativeLiquidity: number;
    juniorLpPoints: number;
    liquidityBoostPoints: number;
    liquidityPoints: number;
    liquidityPointsUpdatedAt: string;
    seniorLpPoints: number;
    pendingLockedPoints: number;
    lockedPoints: number;
    seniorTrancheLocked: number;
    juniorTrancheLocked: number;
  };
  badges: Array<BadgeData>;
  boost: {
    liquidityBoost: number;
    volumeBoost: number;
    referralBoost: number;
    baseLiquidityBoost: number;
  };
  rank: {
    volumePoints: {
      rank: number;
      totalUsers: number;
    };
    liquidityPoints: {
      rank: number;
      totalUsers: number;
    };
    totalPoints: {
      rank: number;
      totalUsers: number;
    };
  };
  dailyLpPoints: number;
};

const useUserPoints = () => {
  const { address } = useAccount();

  const { data: userPointsData, isFetching: isLoadingPoints } = useQuery({
    queryKey: ['points-info', address],
    queryFn: async () => {
      const response = await requestApp<PointsResponse>({
        endpoint: `/points/info/${address}`,
        version: 'v2',
      });
      return response;
    },

    enabled: !!address,
  });

  const {
    totalVolumeData: { data: tVolumeData, isLoading: totalVolumeIsLoading },
  } = usePortfolioStats();

  const userPoints = useMemo(() => {
    if (userPointsData?.success) {
      return {
        trading: {
          pointsRaw:
            (userPointsData?.points.volumePoints || 0) +
            (userPointsData?.points.referralPoints || 0),
          points: formatNumberWithDecimal(
            (userPointsData?.points.volumePoints || 0) +
              (userPointsData?.points.referralPoints || 0)
          ),
          volume: {
            points: userPointsData?.points.volumePoints,
            boostPoints: `${formatNumber(Math.max(userPointsData?.boost?.volumeBoost || 0, 1))}x`,
          },
          referral: {
            points: userPointsData?.points.referralPoints,
            boostPoints: `${formatNumber(Math.max(userPointsData?.boost?.referralBoost || 0, 1))}x`,
          },
          boostPoints: userPointsData?.points.volumeBoostPoints,
          cumulativeVolume: formatNumber(userPointsData?.points.cumulativeTradingVolume),
          boost: `${formatNumber(
            Math.max(
              (userPointsData?.boost?.volumeBoost || 0) === 1
                ? 0
                : (userPointsData?.boost?.volumeBoost || 0) +
                      (userPointsData?.boost?.referralBoost || 0) ===
                    1
                  ? 0
                  : userPointsData?.boost?.referralBoost || 0,
              1
            )
          )}x`,
          rank:
            Math.min(
              userPointsData?.rank.volumePoints.rank,
              userPointsData?.rank.volumePoints.totalUsers
            ) || '-',
          totalUsers: userPointsData?.rank.volumePoints.totalUsers || '-',
          percentile: calculateUserPercentile(
            Math.min(
              userPointsData?.rank.volumePoints.rank,
              userPointsData?.rank.volumePoints.totalUsers
            ),
            Number(userPointsData?.rank.volumePoints.totalUsers)
          ),
        },
        liquidity: {
          pointsRaw: userPointsData?.points.liquidityPoints,
          points: formatNumberWithDecimal(userPointsData?.points.liquidityPoints),
          boostPoints: userPointsData?.points.liquidityBoostPoints,
          cumulativeVolume: formatNumber(userPointsData?.points.cumulativeLiquidity),
          boost: `${formatNumber(Math.max(userPointsData?.boost?.liquidityBoost || 0, 1))}x`,
          rank:
            Math.min(
              userPointsData?.rank.liquidityPoints.rank,
              userPointsData?.rank.liquidityPoints.totalUsers
            ) || '-',
          juniorTrancheLocked: formatNumber(Math.abs(userPointsData?.points?.juniorLpPoints || 0)),
          seniorTrancheLocked: formatNumber(Math.abs(userPointsData?.points?.seniorLpPoints || 0)),
          lockedPoints: formatNumber(Math.abs(userPointsData?.points?.lockedPoints || 0)),
          pendingLockedPoints: formatNumber(
            Math.abs(userPointsData?.points?.pendingLockedPoints || 0)
          ),
          totalUsers: userPointsData?.rank.liquidityPoints.totalUsers || 0,
          dailyPoints: userPointsData?.dailyLpPoints
            ? formatNumber(userPointsData?.dailyLpPoints)
            : 0,
          percentile: calculateUserPercentile(
            Math.min(
              userPointsData?.rank.liquidityPoints.rank,
              userPointsData?.rank.liquidityPoints.totalUsers
            ),
            Number(userPointsData?.rank.liquidityPoints.totalUsers)
          ),
        },
        lpPointsData: {
          dailyPoints: userPointsData?.dailyLpPoints
            ? formatNumber(userPointsData?.dailyLpPoints)
            : 0,
          juniorTrancheLocked: userPointsData?.points?.juniorLpPoints || 0,
          seniorTrancheLocked: userPointsData?.points?.seniorLpPoints || 0,
          pendingLockedPoints: userPointsData?.points?.pendingLockedPoints || 0,
          lockedPoints: userPointsData?.points?.lockedPoints || 0,
        },
        rankData: userPointsData?.rank,
        totalPoints: userPointsData?.points.totalPoints,
        totalVolume: tVolumeData?.data?.[0]?.total ? tVolumeData?.data?.[0]?.total : '',
        badges: [...userPointsData?.badges],
      };
    } else {
      return null;
    }
  }, [userPointsData, tVolumeData]);

  return { data: userPoints, isLoading: isLoadingPoints || totalVolumeIsLoading };
};

export default useUserPoints;
