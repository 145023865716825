import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';
const Pin = ({
  pin,
  setPin
}: {
  pin: string;
  setPin: Dispatch<SetStateAction<string>>;
}) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const isTabletOrDesktop = useMediaQuery('(min-width: 768px)');
  useEffect(() => {
    const timer = setTimeout(() => {
      firstInputRef.current?.focus();
    }, 0);
    return () => clearTimeout(timer);
  }, []);
  return <InputOTP maxLength={4} value={pin} onChange={value => setPin(value)} ref={isTabletOrDesktop ? firstInputRef : null} data-sentry-element="InputOTP" data-sentry-component="Pin" data-sentry-source-file="Pin.tsx">
      <InputOTPGroup data-sentry-element="InputOTPGroup" data-sentry-source-file="Pin.tsx">
        <InputOTPSlot index={0} data-sentry-element="InputOTPSlot" data-sentry-source-file="Pin.tsx" />
        <InputOTPSlot index={1} data-sentry-element="InputOTPSlot" data-sentry-source-file="Pin.tsx" />
        <InputOTPSlot index={2} data-sentry-element="InputOTPSlot" data-sentry-source-file="Pin.tsx" />
        <InputOTPSlot index={3} data-sentry-element="InputOTPSlot" data-sentry-source-file="Pin.tsx" />
      </InputOTPGroup>
    </InputOTP>;
};
export default Pin;