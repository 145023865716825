'use client';

import React, { useState } from 'react';
import ResponsivePopover from '../ResponsivePopover';
import Image from 'next/image';
import useUserPoints from '@/hooks/xp/useUserPoints';
import { cn, formatNumber } from '@/lib/utils';
import { ArrowRight } from 'lucide-react';
import Link from 'next/link';
const AvantisXP = () => {
  const {
    data: userPoints
  } = useUserPoints();
  const [season, setSeason] = useState<number>(2);
  return <ResponsivePopover trigger={<div className="group relative flex items-center w-9 h-8 md:h-11 xl:w-fit  bg-grey-600 rounded-md">
          <div className="absolute hidden group-hover:block inset-0 border-1 border-transparent rounded-md bg-gradient-to-r from-primary-100 via-[#fdeac5] to-[#6e8f32] bg-[length:300%_300%] animate-linearGradient -m-0.5 p-0.5"></div>
          <div className="px-3 xl:px-4 py-1 flex items-center gap-2 z-20 bg-grey-600 w-full h-full rounded-md">
            <Image src="/images/points/reward-star.svg" alt="xp-icon" width={20} height={16} />
            <span className="hidden xl:block">Avantis XP</span>
          </div>
        </div>} data-sentry-element="ResponsivePopover" data-sentry-component="AvantisXP" data-sentry-source-file="AvantisXP.tsx">
      <div className="flex justify-around bg-grey-800 border border-grey-600 rounded-sm text-grey-300-disabled-text mb-4">
        <button onClick={() => setSeason(1)} className={cn(season === 1 ? 'text-grey-050 bg-grey-500 outline outline-grey-400 rounded-sm' : '', 'w-full py-1 text-sm text-center')}>
          Season 1 (ended)
        </button>
        <button onClick={() => setSeason(2)} className={cn(season === 2 ? 'text-grey-050 bg-grey-500 outline outline-grey-400 rounded-sm' : '', 'w-full py-1 text-sm text-center')}>
          Season 2
        </button>
      </div>
      {season === 1 ? <>
          <div className="space-y-3">
            <div className="flex justify-between">
              <div className="flex items-center gap-1 text-sm text-grey-100">
                <Image src={'/images/points/xp-trading.svg'} width={16} height={16} alt="Trading XP" />
                Trading XP
              </div>
              <p className="text-lg font-fono">{formatNumber(userPoints?.trading.pointsRaw)}</p>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-1 text-sm text-grey-100">
                <Image src={'/images/points/xp-liquidity.svg'} width={16} height={16} alt="Liquidity XP" />
                Liquidity XP
              </div>
              <p className="text-lg font-fono">{formatNumber(userPoints?.liquidity.pointsRaw)}</p>
            </div>
          </div>
          <Link href="/xp">
            <div role="button" className="mt-3 gap-1 bg-grey-500 w-full p-2.5 text-sm rounded-md flex items-center justify-center">
              View Avantis XP Dashboard <ArrowRight size={16} />
            </div>
          </Link>
        </> : <div className="flex items-end justify-between gap-2">
          <div className="flex flex-col h-10">
            <p className="text-grey-100 font-normal text-sm">Season 2 coming soon.</p>
            <p className="text-grey-100 font-normal text-sm">XP is accruing.</p>
          </div>
          <div className="relative w-20 h-6">
            <div className="tetrominos">
              <div className="tetromino box1"></div>
              <div className="tetromino box2"></div>
              <div className="tetromino box3"></div>
              <div className="tetromino box4"></div>
            </div>
          </div>
        </div>}
    </ResponsivePopover>;
};
export default AvantisXP;