import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { ChevronUp } from 'lucide-react';
import { useState } from 'react';
import Link from 'next/link';
const MoreDropDown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return <DropdownMenu open={openDropdown} onOpenChange={() => setOpenDropdown(false)} data-sentry-element="DropdownMenu" data-sentry-component="MoreDropDown" data-sentry-source-file="MoreDropDown.tsx">
      <DropdownMenuTrigger asChild onMouseEnter={() => setOpenDropdown(true)} data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="MoreDropDown.tsx">
        <Button variant="navLink" data-sentry-element="Button" data-sentry-source-file="MoreDropDown.tsx">
          <p>More</p>
          <ChevronUp className={!openDropdown ? 'rotate-180 transition-transform' : 'rotate-0 transition-transform'} data-sentry-element="ChevronUp" data-sentry-source-file="MoreDropDown.tsx" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent onMouseLeave={() => setOpenDropdown(false)} className="flex flex-col items-center justify-center text-grey-100 mt-2" data-sentry-element="DropdownMenuContent" data-sentry-source-file="MoreDropDown.tsx">
        <Link href="https://analytics.avantisfi.com/" target="_blank" className="py-2" data-sentry-element="Link" data-sentry-source-file="MoreDropDown.tsx">
          Analytics
        </Link>
        <Link href="/bridge" className="py-2" data-sentry-element="Link" data-sentry-source-file="MoreDropDown.tsx">
          Bridge
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>;
};
export default MoreDropDown;