import React from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../ui/dropdown-menu';
import usePairs from '@/hooks/pairs/usePairs';
import { PairIcon } from './pairSelector/PairLabel';
import { PendingOrderType } from '@/hooks/trade/usePendingOrders';
const PendingOrders = ({
  pendingOrders
}: {
  pendingOrders: PendingOrderType[];
}) => {
  const {
    pairsObjAll
  } = usePairs();
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="PendingOrders" data-sentry-source-file="PendingOrders.tsx">
      <DropdownMenuTrigger asChild className="flex relative items-center justify-center" data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="PendingOrders.tsx">
        <span className="relative flex items-center justify-center h-6 w-6 mr-2 cursor-pointer">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-200 opacity-75"></span>
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col items-center justify-center bg-grey-800 w-80 p-4 gap-2" data-sentry-element="DropdownMenuContent" data-sentry-source-file="PendingOrders.tsx">
        <p className="w-full text-grey-200">
          {pendingOrders.length} pending order{pendingOrders.length > 1 ? 's' : ''}
        </p>

        {pendingOrders.map(trade => {
        return <div key={trade.orderId} className="flex items-center rounded h-14 py-2 pr-3 bg-grey-600 relative w-full">
              <span className="m-0 text-center absolute bg-secondary-300 rounded-tl-lg rounded-tr-lg text-black w-14 py-1 -left-4 -rotate-90">
                {trade.isOpening ? 'OPEN' : 'CLOSE'}
              </span>
              <div className="flex w-full flex-grow justify-between items-end ml-8">
                <div className={'lg:block flex-col'}>
                  <div className="pl-0 flex items-center gap-2">
                    <p className="text-grey-200">
                      {pairsObjAll[trade.pairIndex]?.from}-{pairsObjAll[trade.pairIndex]?.to}{' '}
                    </p>

                    <div className="relative w-5 h-5">
                      <PairIcon pairInfo={pairsObjAll[trade.pairIndex]} size="size-5" />
                    </div>
                  </div>
                </div>
                <span className="text-green-300">#{trade.orderId}</span>
              </div>
            </div>;
      })}
      </DropdownMenuContent>
    </DropdownMenu>;
};
export default PendingOrders;