import { serializeError } from '@/lib/transaction';
import useTransaction from '../transaction/useTransaction';
import useAppToast from '../shared/useAppToast';
import { contractsConfig } from '@/blockchain/contracts';
import { usePublicClient } from 'wagmi';
import { sendTransaction } from '@wagmi/core';
import { config } from '@/blockchain/configs/wagmi';
import { Address } from 'viem';
import { useOneCTStore } from '@/store/oneCT';
import { privateKeyToAccount } from 'viem/accounts';
import { useAccount } from 'wagmi';
import { captureError } from '@/lib/sentry';
import { EVENTS, trackEvent } from '@/providers/AnalyticsProvider';

const useWalletTransaction = () => {
  const { address } = useAccount();
  const { executeTransaction } = useTransaction();
  const toast = useAppToast();
  const { privateKey } = useOneCTStore((state) => state);
  const client = usePublicClient();

  const depositUSDC = ({ to, amount }: { to: Address; amount: bigint }) => {
    trackEvent(EVENTS.USDC_DEPOSITED_CTA, [to, amount.toString()]);

    try {
      executeTransaction(
        {
          ...contractsConfig.USDC,
          functionName: 'transfer',
          args: [to, amount],
          txSuccessCallback: () =>
            trackEvent(EVENTS.USDC_DEPOSITED_SUCCESS, [to, amount.toString()]),
          txFailedCallback: () => trackEvent(EVENTS.USDC_DEPOSITED_FAIL, [to, amount.toString()]),
        },
        true
      );
    } catch (e) {
      const error = serializeError(e);
      captureError(error, { address, action: 'depositUSDC', to, amount });
      toast.failed({ error: error.message });
      trackEvent(EVENTS.USDC_DEPOSITED_FAIL, [to, amount.toString()]);
      console.error('Approval failed:', error);
      return false;
    }
  };

  const withdrawUSDC = ({
    to,
    amount,
    txSuccessCallback,
    txFailedCallback,
  }: {
    to: Address;
    amount: bigint;
    txSuccessCallback?: () => void;
    txFailedCallback?: () => void;
  }) => {
    try {
      trackEvent(EVENTS.USDC_WITHDRAWN_CTA, [to, amount.toString()]);
      executeTransaction({
        ...contractsConfig.USDC,
        functionName: 'transfer',
        args: [to, amount],
        txSuccessCallback: () => {
          trackEvent(EVENTS.USDC_WITHDRAWN_SUCCESS, [to, amount.toString()]);
          txSuccessCallback?.();
        },
        txFailedCallback: () => {
          trackEvent(EVENTS.USDC_WITHDRAWN_FAIL, [to, amount.toString()]);
          txFailedCallback?.();
        },
      });
    } catch (e) {
      const error = serializeError(e);
      captureError(error, { address, action: 'withdrawUSDC', to, amount });
      toast.failed({ error: error.message });
      console.error('Approval failed:', error);
      trackEvent(EVENTS.USDC_WITHDRAWN_FAIL, [to, amount.toString()]);
      return false;
    }
  };

  const depositETH = async ({
    to,
    amount,
    txSuccessCallback,
    txFailedCallback,
  }: {
    to: Address;
    amount: bigint;
    txSuccessCallback?: () => void;
    txFailedCallback?: () => void;
  }) => {
    try {
      trackEvent(EVENTS.ETH_DEPOSITED_CTA, [to, amount.toString()]);
      toast.txnInitiate();
      const tx = await sendTransaction(config, {
        to,
        value: amount,
      });

      toast.processing();

      const receipt = await client.waitForTransactionReceipt({ hash: tx! });

      if (receipt.status === 'success') {
        toast.txnSuccess(tx!);
        trackEvent(EVENTS.ETH_DEPOSITED_SUCCESS, [to, amount.toString()]);
        txSuccessCallback?.();
        return true;
      }
      toast.failed({ error: 'Transaction failed.' });
      trackEvent(EVENTS.ETH_DEPOSITED_FAIL, [to, amount.toString()]);
      txFailedCallback?.();

      return false;
    } catch (e) {
      const error = serializeError(e);
      captureError(error, { address, action: 'depositETH', to, amount });
      toast.failed({ error: error.message });
      trackEvent(EVENTS.ETH_DEPOSITED_FAIL, [to, amount.toString()]);
      return false;
    }
  };

  const withdrawETH = async ({
    to,
    amount,
    txSuccessCallback,
    txFailedCallback,
  }: {
    to: Address;
    amount: bigint;
    txSuccessCallback?: () => void;
    txFailedCallback?: () => void;
  }) => {
    try {
      if (!privateKey) return;
      trackEvent(EVENTS.ETH_WITHDRAWN_CTA, [to, amount.toString()]);

      toast.txnInitiate();

      const account = privateKeyToAccount(privateKey);

      const tx = await sendTransaction(config, {
        account,
        to,
        value: amount,
      });

      toast.processing();

      const receipt = await client.waitForTransactionReceipt({ hash: tx! });

      if (receipt.status === 'success') {
        toast.txnSuccess(tx!);
        txSuccessCallback?.();
        trackEvent(EVENTS.ETH_WITHDRAWN_SUCCESS, [to, amount.toString()]);
        return true;
      }
      toast.failed({ error: 'Transaction failed.' });
      trackEvent(EVENTS.ETH_WITHDRAWN_FAIL, [to, amount.toString()]);
      txFailedCallback?.();

      return false;
    } catch (e) {
      const error = serializeError(e);
      captureError(error, { address, action: 'withdrawETH', to, amount });
      toast.failed({ error: error.message });
      trackEvent(EVENTS.ETH_WITHDRAWN_FAIL, [to, amount.toString()]);
      return false;
    }
  };

  return { depositETH, withdrawETH, depositUSDC, withdrawUSDC };
};

export default useWalletTransaction;
