import { useState } from 'react';
import ManageFundsModal from './ManageFundsModal';
import AdjustBalanceButtons from '../../header/AdjustBalanceButtons';
import { noOp } from '@/constants/common';
export type IAsset = 'ETH' | 'USDC';
const ManageFunds = ({
  isAdd,
  asset,
  onComplete = noOp
}: {
  isAdd: boolean;
  asset: IAsset;
  onComplete?: () => void;
}) => {
  const [openManageFunds, setOpenManageFunds] = useState<boolean>(false);
  return <>
      <AdjustBalanceButtons isAdd={isAdd} setOpenManageFunds={setOpenManageFunds} data-sentry-element="AdjustBalanceButtons" data-sentry-source-file="ManageFunds.tsx" />
      {openManageFunds && <ManageFundsModal isAdd={isAdd} asset={asset} onComplete={onComplete} setOpenManageFunds={setOpenManageFunds} />}
    </>;
};
export default ManageFunds;