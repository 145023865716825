import { Assets } from '@/hooks/shared/useWalletBalance';
import { IAsset } from './ManageFunds';
import { HiLightningBolt } from 'react-icons/hi';
const BalanceSection = ({
  asset,
  balance
}: {
  asset: IAsset;
  balance: Assets | null;
}) => {
  if (asset === 'ETH') {
    return <div className="bg-grey-600 rounded-lg flex justify-between p-4">
        <div className="flex items-center gap-4">
          <HiLightningBolt className="text-primary size-6" />
          <p className="text-grey-000 text-lg font-normal">Gas Balance</p>
        </div>

        <p className="font-fono text-lg font-normal text-muted">{`${balance?.eth?.formatted.toString()} ${asset}`}</p>
      </div>;
  }
  return <div className="bg-grey-600 rounded-lg flex justify-between p-4" data-sentry-component="BalanceSection" data-sentry-source-file="BalanceSection.tsx">
      <div className="flex items-center gap-4">
        <HiLightningBolt className="text-primary size-6" data-sentry-element="HiLightningBolt" data-sentry-source-file="BalanceSection.tsx" />
        <p className="text-grey-000 text-lg font-normal">Trading Balance</p>
      </div>

      <p className="font-fono text-lg font-normal text-muted">{`${balance?.usdc?.formatted.toString()} ${asset}`}</p>
    </div>;
};
export default BalanceSection;