import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Button } from '@/components/ui/button';
type NavLinkProps = {
  path: '/trade' | '/portfolio' | '/earn' | '/leaderboard' | '/referral';
  name: 'Trade' | 'Portfolio' | 'Earn' | 'Leaderboard' | 'Referral';
};
const NavLink = ({
  path,
  name
}: NavLinkProps) => {
  const pathname = usePathname();
  return <Link href={path} data-sentry-element="Link" data-sentry-component="NavLink" data-sentry-source-file="NavLinks.tsx">
      <Button variant="navLink" className={pathname == path ? 'text-grey-000 bg-grey-1200' : 'text-grey-200 hover:text-grey-000'} data-sentry-element="Button" data-sentry-source-file="NavLinks.tsx">
        {name}
      </Button>
    </Link>;
};
const links: NavLinkProps[] = [{
  path: '/trade',
  name: 'Trade'
}, {
  path: '/portfolio',
  name: 'Portfolio'
}, {
  path: '/earn',
  name: 'Earn'
}, {
  path: '/leaderboard',
  name: 'Leaderboard'
}, {
  path: '/referral',
  name: 'Referral'
}];
const NavLinks = () => {
  return links.map((link, idx) => {
    return <NavLink path={link.path} name={link.name} key={idx} />;
  });
};
export default NavLinks;