import { Dispatch, SetStateAction } from 'react';
import Pin from './Pin';
import useOneCT from '@/hooks/shared/useOneCT';
import { useUserStore } from '@/store/user';
const PinModalContent = ({
  pin,
  setPin,
  pinError
}: {
  pin: string;
  setPin: Dispatch<SetStateAction<string>>;
  pinError: boolean;
}) => {
  const {
    oneCTWallets
  } = useOneCT();
  const {
    updateUserStore
  } = useUserStore();
  return <div className="py-6 px-11 flex flex-col items-center justify-center gap-6" data-sentry-component="PinModalContent" data-sentry-source-file="PinModalContent.tsx">
      <p className="text-grey-100 font-normal text-base">
        Please enter your 4-digit PIN to re-enable 1CT.
      </p>
      <Pin setPin={setPin} pin={pin} data-sentry-element="Pin" data-sentry-source-file="PinModalContent.tsx" />

      {pinError && <div className="flex flex-col gap-2 items-center">
          <p className="text-red-500 font-normal text-sm">Invalid PIN. Please try again.</p>
          {oneCTWallets.length < 2 && <div className="inline-flex gap-6 md:gap-1 cursor-pointer text-sm">
              <p className="text-grey-100">Forgot your PIN?</p>
              <p role="button" className="text-primary-100" onClick={() => {
          updateUserStore('openOnboard1CT', true);
        }}>
                Create another 1CT wallet
              </p>
            </div>}
        </div>}
    </div>;
};
export default PinModalContent;